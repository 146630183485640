import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboard/Home.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },

    {
      path: '/home-credor',
      name: 'home-credor',
      component: () => import('@/views/acesso-credor/dashboard/Home.vue'),
      meta: {
        resource: 'Credor',
        action: 'read'
      },
    },
    {
      path: '/credor/devedores',
      name: 'cliente-credor',
      component: () => import('@/views/acesso-credor/cliente/cliente-list/ClienteList.vue'),
      meta: {
        resource: 'Credor',
        action: 'read'
      },
    },
    {
      path: '/credor/devedores/editar/:id',
      name: 'edit-cliente-credor',
      component: () => import('@/views/acesso-credor/cliente/cliente-list/ClienteEdit.vue'),
      meta: {
        resource: 'Credor',
        action: 'read'
      },
    },
    {
      path: '/credor/titulos',
      name: 'titulo-credor',
      component: () => import('@/views/acesso-credor/titulo/titulo-list/TituloList.vue'),
      meta: {
        resource: 'Credor',
        action: 'read'
      },
    },

    // *-- CREDOR --*
    {
      path: '/credores/lista',
      name: 'credor-list',
      component: () => import('@/views/credor/credor-list/CredorList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
    {
      path: '/credores/:id',
      name: 'credor-view',
      component: () => import('@/views/credor/credor-view/CredorView.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
    {
      path: '/credores/editar/:id',
      name: 'credor-edit',
      component: () => import('@/views/credor/credor-edit/CredorEdit.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },

    // *-- CLIENTE --*
    {
      path: '/devedores/lista',
      name: 'cliente-list',
      component: () => import('@/views/cliente/cliente-list/ClienteList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
    {
      path: '/devedores/editar/:id',
      name: 'cliente-edit',
      component: () => import('@/views/cliente/cliente-edit/ClienteEdit.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
  
    // *-- COBRANCA --*
    {
      path: '/cobrancas/lista',
      name: 'cobranca-list',
      component: () => import('@/views/cobranca/cobranca-list/CobrancaList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },

    // *-- TITULO --*
    {
      path: '/titulos/lista',
      name: 'titulo-list',
      component: () => import('@/views/titulo/titulo-list/TituloList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },

    // *-- MENSAGEM --*
    {
      path: '/mensagens/lista',
      name: 'mensagem-list',
      component: () => import('@/views/mensagem/mensagem-list/MensagemList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
    {
      path: '/mensagens/criar',
      name: 'mensagem-create',
      component: () => import('@/views/mensagem/mensagem-create/MensagemCreate.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
    {
      path: '/mensagens/editar/:id',
      name: 'mensagem-edit',
      component: () => import('@/views/mensagem/mensagem-edit/MensagemEdit.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
    {
      path: '/mensagens/layouts',
      name: 'layouts-email-list',
      component: () => import('@/views/mensagem/layouts-email/LayoutList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
    {
      path: '/mensagens/layouts/editor/:layoutId?',
      name: 'layout-editor',
      component: () => import('@/views/mensagem/layouts-email/Editor.vue'),
      meta: {
        resource: 'Admin',
        action: 'read',
        layout: 'full',
      },
    },


    // *-- USUARIO --*
    {
      path: '/usuarios/lista',
      name: 'usuario-list',
      component: () => import('@/views/usuario/usuario-list/UsuarioList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
    {
      path: '/usuarios/editar/:id',
      name: 'usuario-edit',
      component: () => import('@/views/usuario/usuario-edit/UsuarioEdit.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
    {
      path: '/usuarios/alterar-senha/:id',
      name: 'usuario-edit-password',
      component: () => import('@/views/usuario/usuario-edit/UsuarioEditPassword.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },

    // *-- IMPORTACAO --*
    {
      path: '/importacoes/lista',
      name: 'importacao-list',
      component: () => import('@/views/importacao/importacao-list/ImportacaoList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },

    // *-- CARTA --*
    {
      path: '/cartas/lista',
      name: 'carta-list',
      component: () => import('@/views/carta/carta-list/CartaList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },

    // *-- RELATORIOS --*
    {
      path: '/relatorios/cobrancas',
      name: 'relatorio-cobrancas',
      component: () => import('@/views/relatorios/historico/HistoricoList.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },
    {
      path: '/relatorios/excel',
      name: 'relatorio-excel',
      component: () => import('@/views/relatorios/excel/Index.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },

    {
      path: '/regua-cobranca',
      name: 'collection-index',
      component: () => import('@/views/regua-cobranca/Index.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },

    {
      path: '/configuracoes',
      name: 'settings',
      component: () => import('@/views/configuracoes/Index.vue'),
      meta: {
        resource: 'Admin',
        action: 'read'
      },
    },


    // *-- AUTH --*
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    }, 
    {
      path: '/acesso-credor',
      name: 'login-credor',
      component: () => import('@/views/auth/credor/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    }, 
    {
      path: '/esqueceu-senha',
      name: 'forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },   
    {
      path: '/nao-autorizado',
      name: 'not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    }   
  ],
})

router.beforeEach((to, _, next) => {

  if(window.location.hostname == 'recebe-easy.com.br'){
   window.location.href = "https://a-creditar.com/acesso-credor"
  }
 
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
