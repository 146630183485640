import Vue from 'vue'
import axios from 'axios'

const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000/api";

const axiosIns = axios.create({
  baseURL: baseURL, 
  headers: {'Accept': 'application/json'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
