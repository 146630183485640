import authDefaultConfig from '@/auth/core/authDefaultConfig'
import { initialAbility } from '@/libs/acl/config'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default class AuthProvider {
  // Will be used by this service for making API calls
  axiosIns = null

  // authConfig <= Will be used by this service
  authConfig = { ...authDefaultConfig }

  constructor(axiosIns, authOverrideConfig) {
    this.axiosIns = axiosIns
    this.authConfig = { ...this.authConfig, ...authOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.authConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        // const originalRequest = config

        if (response && response.status === 401) {
          if(error.response.data.message != 'Invalid login details'){
            const user = JSON.parse(localStorage.getItem('userData'))

            localStorage.removeItem(this.authConfig.storageTokenKeyName)
  
            // Remove userData from localStorage
            localStorage.removeItem('userData')
  
            // Reset ability
            this.$ability.update(initialAbility)
  
            // Redirect to login page
            this.$router.push(getHomeRouteForLoggedInUser(user.role))
          }else{
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                // title: `Welcome ${userData.fullName || userData.username}`,
                title: 'Falha na autenticação',
                icon: 'XIcon',
                variant: 'danger',
                // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                text: `Por favor verifique suas credenciais e tente novamente.`,
              },
            })
          }      
        }
        // if (response && response.status === 401) {
          // if (!this.isAlreadyFetchingAccessToken) {
          //   this.isAlreadyFetchingAccessToken = true
          //   this.refreshToken().then(r => {
          //     this.isAlreadyFetchingAccessToken = false

          //     // Update accessToken in localStorage
          //     this.setToken(r.data.accessToken)
          //     this.setRefreshToken(r.data.refreshToken)

          //     this.onAccessTokenFetched(r.data.accessToken)
          //   })
          // }
          // const retryOriginalRequest = new Promise(resolve => {
          //   this.addSubscriber(accessToken => {
          //     // Make sure to assign accessToken according to your response.
          //     // Check: https://pixinvent.ticksy.com/ticket/2413870
          //     // Change Authorization header
          //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          //     resolve(this.axiosIns(originalRequest))
          //   })
          // })
          // return retryOriginalRequest
        // }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.authConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.authConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.authConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.authConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.authConfig.loginEndpoint, ...args)
  }

  loginCredor(...args) {
    return this.axiosIns.post(this.authConfig.loginCredorEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.authConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.authConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
